
import React from 'react'
import { Link } from 'react-router-dom'

import '../../useStore/useStore'
import './Header.scss'


function Header() {





    return (
        <div className='header'>
            <div className='header-container'>
                <div className='header-container__left'>
                    <p className='header-logo'>ANDYSONG</p>
                </div>
                <div className='header-container__right'>
                    <div className='header-menu'>
                        <Link to='/' className='link'  >
                            <p className='header-home'>Home</p>
                        </Link>
                        <p className='header-bar'>|</p>
                    </div>
                    <div className='header-menu'>
                        <Link to='/about' className='link'  >
                            <p className='header-about'>About</p>
                        </Link>
                    </div>
                    <div className='header-menu'>
                        <p className='header-bar'>|</p>
                        <Link to='/projects' className='link'  >
                            <p className='header-project'>Projects</p>
                        </Link>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default Header