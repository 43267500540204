
import create from 'zustand'

const useStore = create((set, get) => {
    return {
        set,
        get,
        moveBall: false,
        headerActive: false,
        hasMovedBall: (ball) => set(state => ({ moveBall: ball })),
        setHeaderActive: (header) => set(state => ({ headerActive: header })),
    }
})

export { useStore }