import React from 'react'
import { useState, useEffect } from 'react'

import { useProgress } from '@react-three/drei'

import Loader from './Loader'

import './Overlay.scss'

const Overlay = () => {

    const [shown, setShown] = useState(true)
    const [opaque, setOpaque] = useState(true)
    const [hasLoaded, setHasLoaded] = useState(false)
    const [hasInteracted, setHasInteracted] = useState(false)
    const { active, progress } = useProgress()


    useEffect(() => {
        if (hasInteracted) {
            setShown(false)
        } else if (!hasInteracted) {
            setShown(true)
        }
    }, [hasInteracted, active])


    useEffect(() => {
        let t
        if (hasLoaded === opaque)
            t = setTimeout(() => setOpaque(!hasLoaded), 300)
        return () => clearTimeout(t)
    }, [hasLoaded, opaque])

    useEffect(() => {
        if (progress >= 100) {
            setHasLoaded(true)
        }
    }, [progress])


    return shown ? (
        <div
            onClick={() => setHasInteracted(true)}
            className={`loading`}
            style={{ opacity: shown ? 1 : 0, background: opaque ? '#fe2079FF' : '#ffffffff' }}
        >
            {!hasLoaded ? (
                <Loader active={active} progress={progress} />
            ) : (
                <>
                    <div className='overlay' >
                        <div className='overlay-container-title'>
                            <pre className='overlay-title'> W E L C O M E </pre>
                        </div>
                        <div className='overlay-container-subtitle'>
                            <pre className='overlay-subtitle'> CLICK ANYWHERE TO START </pre>
                        </div>
                    </div>
                </>
            )}
        </div>
    ) : null
}

export default Overlay