import React from 'react'
import '../Page/Landing.scss'

function Landing() {



    return (
        <>
            <div className='landing'>
                <div className='landing-container-title'>
                    <pre className='landing-title'>A N D Y    S O N G</pre>
                </div>
                <div className='landing-container-subtitle'>
                    <pre className='landing-subtitle'> Full Stack Web Developer</pre>
                </div>

            </div>
        </>
    )
}

export default Landing
