import React from 'react'
import './About.scss'
import linkedinLogo from '../../Assets/images/linkedin-logo.png'
import githubLogo from '../../Assets/images/github-logo.png'
import emailLogo from '../../Assets/images/email.png'

function About() {
    return (
        <>
            <div className='scroll '>
                <section className='about'>
                    <div className='about-container'>
                        <pre className='about-title'>A B O U T</pre>
                    </div>

                    <div className='about-card'>
                        <div className='about-card__container'>
                            <p className='about-card__description'>Hello! My name is Andy. I'm an aspiring Full Stack Web Developer who enjoys solving problems and creating unique solutions. </p>
                            <p className='about-card__description'> My passion for problem-solving strives me to continually learn and create the best website for the end-user </p>
                            <p className='about-card__description'> I would love the opportunity to work, collaborate and learn alongside other like-minded professionals</p>
                        </div>
                        <div className='about-card__language'>

                        </div>
                        <div className='about-card__contact'>
                            <a className='about-card__contact-linkedin' href='https://www.linkedin.com/in/andy-songc/' target={"_blank"} rel='noreferrer'>
                                <img className='about-card__contact-linkedin-logo' src={linkedinLogo} alt='linkedin ' />
                                <p className='about-card__contact-linkedin-text'>Linkedin</p>
                            </a>
                            <a className='about-card__contact-github' href='https://github.com/Andycsong' target={"_blank"} rel='noreferrer'>
                                <img className='about-card__contact-github-logo' src={githubLogo} alt='github' />
                                <p className='about-card__contact-github-text'>Github</p>
                            </a>
                            <a className='about-card__contact-contact' href='mailto:andysong712@gmail.com' target={"_blank"} rel='noreferrer'>
                                <img className='about-card__contact-contact-logo' src={emailLogo} alt='email' />
                                <p className='about-card__contact-contact-text'>Contact</p>
                            </a>
                        </div>
                    </div>


                </section>
            </div>
        </>
    )
}

export default About