import { useTexture } from '@react-three/drei'
import minimalHorizon from '../Assets/textures/minimalHorizon.jpg'
import { BackSide, MirroredRepeatWrapping } from 'three'
import { Suspense, useLayoutEffect, useRef } from 'react'
import { useFrame } from '@react-three/fiber'


function Background() {
    const spaceTexture = useTexture(minimalHorizon)

    useLayoutEffect(() => {
        spaceTexture.wrapS = spaceTexture.wrapT = MirroredRepeatWrapping
        spaceTexture.repeat.set(2, 3)
        spaceTexture.anistropy = 16
    }, [spaceTexture])

    const backgroundRef = useRef();

    useFrame(({ clock }) => {
        const elapsedTime = clock.getElapsedTime();

        backgroundRef.current.rotation.y = elapsedTime / 100;
    })

    return (
        <>
            <mesh
                ref={backgroundRef}
                position={[0, 0, 0]}
                rotation={[0, 0, Math.PI]}>
                <hemisphereLight intensity={0.6} />
                <sphereGeometry
                    attach='geometry'
                    args={[40, 1280, 1280]}
                />
                <meshPhongMaterial
                    emissive={0xfe2079}
                    emissiveIntensity={0.1}
                    fog={false}
                    side={BackSide}
                    attach='material'
                    map={spaceTexture}
                />
            </mesh>
        </>
    )
}

export default function SkyBox() {
    return (
        <Suspense fallback={null}>
            <Background />
        </Suspense>
    )
}