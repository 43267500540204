
import { Canvas } from '@react-three/fiber'
import { Suspense } from 'react';
import { Preload } from '@react-three/drei';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'

import Anim from './Components/Anim'
import { ReflectSphere } from './Components/sphere'
import SkyBox from './Components/background';
import Header from './Page/Header/Header'
import Landing from './Page/Landing'
import About from './Page/About/About'
import Projects from './Page/Projects/Projects';


import './App.scss'
import Overlay from './Page/Overlay/Overlay';



function App() {

  return (
    <>
      <Router>
        <Header />
        <Routes>
          <Route path='/' element={<Landing />} />
          <Route path='/projects' element={<Projects />} />
          <Route path='/about' element={<About />} />
        </Routes>
      </Router>
      <Overlay />
      <Canvas mode="concurrent" dpr={[1, 1.5]}>
        <Suspense fallback={null}>
          <ReflectSphere />
          <SkyBox />
          <Anim />
          <Preload all />
        </Suspense>
      </Canvas>
    </>
  );
}

export default App
