import React from 'react'
import './Projects.scss'

import dogeGameImage from '../../Assets/images/doge-game-revised.jpg'
import brainflixImage from '../../Assets/images/brainflix-desktop-revised.jpg'
import bandsiteImage from '../../Assets/images/bandsite-desktop-revised.jpg'
import pokebattleImage from '../../Assets/images/pokebattle-desktop.jpg'


function Projects() {


    return (
        <>
            <div className='scroll'>
                <section className='projects'>
                    <div className='projects-container'>
                        <pre className='projects-title'>P R O J E C T S</pre>
                    </div>

                    <div className='projects-card'>
                        <div className='projects-card-container'>
                            <img className='projects-card__image' src={dogeGameImage} alt='doge game screenshot' />
                            <div className='projects-card__textbox'>
                                <div className='projects-card__textbox-title'> Doge to the Moon</div>
                                <p className='projects-card__textbox-description'> 3D endless runner browser game. Inspired by Flappy bird with a Dogecoin Theme. Users control a Dogeship and must avoid the blockchain cubes while it's traversing through space.</p>
                                <div className='projects-card__textbox-link-container'>
                                    <a href='https://github.com/Andycsong/Doge-to-The-Moon' target={"_blank"} rel='noreferrer' className='projects-card__textbox-link projects-card__textbox-git'>
                                        Github
                                    </a>
                                    <a href='https://doge-to-the-moon-game.herokuapp.com/' target={"_blank"} rel='noreferrer' className='projects-card__textbox-link'>Website</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='projects-card'>
                        <div className='projects-card-container'>
                            <img className='projects-card__image' src={brainflixImage} alt='brainflix screenshot' />
                            <div className='projects-card__textbox'>
                                <div className='projects-card__textbox-title'> Brainflix</div>
                                <p className='projects-card__textbox-description'>Responsive website with functionality such as a video selector, comments, and video uploader. Data is retrieved and stored using Node Express </p>
                                <div className='projects-card__textbox-link-container'>
                                    <a href='https://github.com/Andycsong/andy-song-brainflix' target={"_blank"} rel='noreferrer' className='projects-card__textbox-link projects-card__textbox-git'> Github</a>
                                    <a href='https://andycsong-brainflix.netlify.app/' target={"_blank"} rel='noreferrer' className='projects-card__textbox-link'>Website</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='projects-card'>
                        <div className='projects-card-container'>
                            <img className='projects-card__image' src={pokebattleImage} alt='pokebattle game screenshot' />
                            <div className='projects-card__textbox'>
                                <div className='projects-card__textbox-title'>PokeBattle</div>
                                <p className='projects-card__textbox-description'>A website that simulates a rudimentary Pokémon battle. Pokémon data is retrieved using the Poke API. Created within 24 hours with Kayla Bonneteau. </p>
                                <div className='projects-card__textbox-link-container'>
                                    <a href='https://github.com/Andycsong/pokebattle-mini-hackathon' target={"_blank"} rel='noreferrer' className='projects-card__textbox-link projects-card__textbox-git'>
                                        Github
                                    </a>
                                    <a href='https://pokebattle-game.netlify.app/' target={"_blank"} rel='noreferrer' className='projects-card__textbox-link'>Website</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='projects-card'>
                        <div className='projects-card-container'>
                            <img className='projects-card__image' src={bandsiteImage} alt='bandsite screenshot' />
                            <div className='projects-card__textbox'>
                                <div className='projects-card__textbox-title'> Bandsite</div>
                                <p className='projects-card__textbox-description'> Responsive website created to mimick a band website. The site includes a photo gallery, comments, concert dates and a soundcloud embedded player</p>
                                <div className='projects-card__textbox-link-container'>
                                    <a href='https://github.com/Andycsong/andy-song-bandsite' target={"_blank"} rel='noreferrer' className='projects-card__textbox-link projects-card__textbox-git'>Github </a>
                                    <a href='https://andycsong-bandsite.netlify.app/' target={"_blank"} rel='noreferrer' className='projects-card__textbox-link'>Website</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </>
    )
}



export default Projects
