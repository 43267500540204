import React, { useRef, useMemo, useEffect } from "react";
import { useFrame, useThree, extend } from "@react-three/fiber";
import { OrbitControls, } from '@react-three/drei'



import * as THREE from "three";

import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer'
import { ShaderPass } from 'three/examples/jsm/postprocessing/ShaderPass'
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass'
import { UnrealBloomPass } from 'three/examples/jsm/postprocessing/UnrealBloomPass'
import { FilmPass } from 'three/examples/jsm/postprocessing/FilmPass'



extend({ EffectComposer, ShaderPass, RenderPass, UnrealBloomPass, FilmPass })

function Effect() {
    const composer = useRef()
    const { scene, gl, size, camera } = useThree()
    const aspect = useMemo(() => new THREE.Vector2(size.width, size.height), [size])
    useEffect(() => void composer.current.setSize(size.width, size.height), [size])
    useFrame(() => composer.current.render(), 1)
    return (
        <effectComposer ref={composer} args={[gl]}>
            <renderPass attachArray="passes" scene={scene} camera={camera} />
            <unrealBloomPass attachArray="passes" args={[aspect, 0.2, 1, 0.4]} />
        </effectComposer>
    )
}

//////////////////////////////////////////
function Move({ children }) {
    const ref = useRef()
    const vec = new THREE.Vector3()
    const { camera, mouse } = useThree()
    useFrame(({ clock }) => {
        camera.position.lerp(vec.set(mouse.x * 2, 0, 3.5), 0.05)
        ref.current.position.lerp(vec.set(mouse.x * 1, mouse.y * 0.1, 0), 0.1)
        ref.current.rotation.y = THREE.MathUtils.lerp(ref.current.rotation.y, (-mouse.x * Math.PI) / 20, 0.1)

    })
    return <group ref={ref}>{children}</group>
}

export function ReflectSphere() {
    // const { scene, gl } = useThree();



    // const cubeRenderTarget = new THREE.WebGLCubeRenderTarget(256, {
    //     format: THREE.RGBFormat,
    //     generateMipmaps: true,
    //     minFilter: THREE.LinearMipmapLinearFilter,
    //     encoding: THREE.sRGBEncoding
    // });
    // const cubeCamera = new THREE.CubeCamera(1, 1000, cubeRenderTarget);
    // cubeCamera.position.set(0, 0, 0);
    // scene.add(cubeCamera);



    // const sphereRef = useRef();

    // useFrame(({ clock, camera }) => {
    //     // const elapsedTime = clock.getElapsedTime();
    //     camera.visible = false;
    //     sphereRef.current.scale.x += Math.sin(clock.getElapsedTime() * 2) / 500
    //     sphereRef.current.scale.y += Math.sin(clock.getElapsedTime() * 2) / 500


    //     // sphereRef.current.position.x = Math.sin(elapsedTime * 1.5) * 4


    //     // cubeCamera.update(gl, scene);
    // })


    return (
        <>
            <Move />
            <Effect />
            <mesh
                position={[0, 3, -10]}
                // ref={sphereRef}
                castShadow

            >
                <ambientLight intensity={1} />
                {/* <sphereGeometry attach="geometry" args={[2, 64, 64]} />
                <meshBasicMaterial
                    attach="material"
                    envMap={cubeCamera.renderTarget.texture}
                    color="white"
                    roughness={0.1}
                    metalness={1}
                /> */}
                <OrbitControls
                    enableZoom={false}
                    enablePan={false}
                    enableRotate={false}
                    zoomSpeed={0.6}
                    panSpeed={0.5}
                    rotateSpeed={0.4}
                />
            </mesh>
        </>
    );
}
